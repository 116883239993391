import React from "react"
import loadable from "@loadable/component"
const SimpleJustified = loadable(() => import("./SimpleJustified"))
const SimpleCentered = loadable(() => import("./SimpleCentered"))
const ImageWithCTA = loadable(() => import("./ImageWithCTA"))
const SplitWithImage = loadable(() => import("./SplitWithImageNew"))
const HireAppConsultant = loadable(() => import("./HireAppConsultant"))
const SplitWithImageRight = loadable(() => import("./SplitWithImageRight"))
export const CTABlock = ({ CTABlock, theme }) => {
  const { type } = CTABlock
  switch (type) {
    case "Simple justified":
      return <SimpleJustified {...CTABlock} />
    case "Simple centered":
      return <SimpleCentered {...CTABlock} theme={theme} />
    case "Image with cta":
      return <ImageWithCTA {...CTABlock} />
    case "Split with image":
      return <SplitWithImage {...CTABlock} />
    case "Hire app consultant":
      return <HireAppConsultant {...CTABlock} />
    case "Right Image with cta":
        return <SplitWithImageRight {...CTABlock} />
    default:
      break
  }
}

export default CTABlock
